/**
 * DivvyHomes (https://www.divvyhomes.com)
 * Copyright © 2016-present Divvy. All rights reserved.
 */

/* eslint-disable global-require */

import url from 'url';

import { getLoginState, loginStates } from '@app/agents/constants';
import { stages } from '@app/application/constants';
import { isAgentRole, isInternalUserRole, roles } from '@app/constants/user';
import { CoreError } from '@app/errors';

import type { DivvyRoute, DivvyRouteContext } from 'src/client/types';
import { isProductionClientEnv } from 'src/client/utils/client';
import { marketingRoutes } from 'src/client/utils/routing';
import { getAccessiblePortalPathnameRegexes, matchesAny } from './portal/portal-route-permissions';

const approvedStagesForHub = [
  stages.CONDITIONALLY_APPROVED,
  stages.UNDERWRITING_HOLD,
  stages.PRE_APPROVED,
  stages.CLOSED,
];

/**
 * Redirects to `pathname` but adds the query params from `context`
 */
const redirectAction = (context: DivvyRouteContext, pathname: string) => ({
  redirect: url.format({
    pathname,
    query: context.query,
  }),
});

// The top-level (parent) route
const routes: DivvyRoute = {
  enableSSR: true,
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '/accounts/launch-mobile',
      load: () => import('./accounts/pages/LaunchMobile'),
    },
    {
      enableSSR: true,
      action(context: DivvyRouteContext) {
        const state = context.store.getState();
        const user = state.user;
        if (user) {
          if (isInternalUserRole(user.role)) {
            return { redirect: '/admin' };
          }

          if (isAgentRole(user.role)) {
            return { redirect: '/agents' };
          }

          if (user.role === roles.CHIP) {
            return { redirect: '/chip' };
          }

          if (!user.tenancyType && approvedStagesForHub.includes(user.dealStage)) {
            return { redirect: '/portal' };
          }

          if (user.tenancyType && approvedStagesForHub.includes(user.dealStage)) {
            return { redirect: '/portal' };
          }

          return { redirect: `/process` };
        }
        return context.next();
      },
      path: '/accounts',
      children: [
        {
          path: '/register',
          children: [
            {
              path: '/homebuyer',
              load: () => import('./accounts/pages/RegisterHomebuyer'),
            },
            {
              path: '/agent',
              load: () => import('./accounts/pages/RegisterAgent'),
            },
            {
              path: '',
              action() {
                return { redirect: '/accounts' };
              },
            },
          ],
        },
        {
          path: '/login',
          children: [
            {
              path: '/sent',
              load: () => import('./accounts/pages/LoginSent'),
            },
            {
              path: '',
              load: () => import('./accounts/pages/Login'),
            },
          ],
        },
        {
          path: '',
          load: () => import('./accounts/pages/Register'),
        },
      ],
    },
    {
      path: '/homeportal',
      action(context: DivvyRouteContext) {
        return redirectAction(context, '/portal');
      },
    },
    {
      enableSSR: true,
      action(context: DivvyRouteContext) {
        const pathname = context.pathname;
        const query = context.query;
        const state = context.store.getState();
        const user = state.user;

        if (!user) {
          const redirectUri = encodeURIComponent(
            `${pathname}?${new URLSearchParams(query).toString()}`,
          );
          return {
            redirect: `/login?redirectUri=${redirectUri}`,
          };
        }

        if (isAgentRole(user.role) || isInternalUserRole(user.role)) {
          return { redirect: `/accounts/login` };
        }

        const accessibleLinkRegexes = getAccessiblePortalPathnameRegexes(context);
        if (!matchesAny(accessibleLinkRegexes, pathname)) {
          // Reject if current path is not accessible
          return { redirect: `/accounts/login` };
        }
        return context.next();
      },
      path: '/portal',
      children: [
        {
          path: '/profile',
          load: () => import('./profile/PortalProfile'),
        },
        {
          path: '/application-summary',
          load: () => import('./process/summary'),
        },
        {
          path: '/favorited-homes',
          load: () => import('./saved-listings'),
        },
        {
          path: '/budget',
          load: () => import('./portal/pages/my-budget'),
        },
        {
          path: '/my-budget',
          action(context: DivvyRouteContext) {
            return redirectAction(context, '/portal/budget');
          },
        },
        {
          action(context: DivvyRouteContext) {
            const state = context.store.getState();
            const user = state.user;
            if (!user?.tenancyType) {
              return { redirect: '/portal' };
            }
            return context.next();
          },
          path: '/documents',
          children: [
            {
              path: '',
              load: () => import('./portal/pages/documents'),
            },
          ],
        },
        {
          path: '/payments',
          children: [
            {
              path: '/success',
              load: () => import('./portal/pages/payments/success'),
            },
            {
              path: '/history',
              load: () => import('./portal/pages/payments/history'),
            },
            {
              path: '/autopay/:action',
              load: () => import('./portal/pages/payments/portal'),
            },
            {
              path: '',
              load: () => import('./portal/pages/payments/portal'),
            },
          ],
        },
        {
          path: '/requests',
          children: [
            {
              path: '/new',
              load: () => import('./portal/pages/portal-requests-new-request'),
            },
            {
              path: '/complete',
              load: () => import('./portal/pages/portal-requests-new-request/request-created'),
            },
            {
              path: '',
              load: () => import('./portal/pages/portal-requests'),
            },
          ],
        },
        {
          path: '/dashboard',
          action(context: DivvyRouteContext) {
            return redirectAction(context, '/portal');
          },
        },
        {
          path: '/approval-requests',
          children: [
            {
              path: '/:approvalRequestId',
              load: () => import('./portal/pages/approval-requests'),
            },
          ],
        },
        {
          path: '/faq',
          children: [
            {
              path: '/flexible-payments',
              load: () => import('./portal/pages/faq/flexible-payments'),
            },
            {
              path: '',
              action(context: DivvyRouteContext) {
                return redirectAction(context, '/portal');
              },
            },
          ],
        },
        {
          path: '/tasks',
          load: () => import('./portal/pages/tasks'),
        },
        {
          path: '/hoa',
          load: () => import('./portal/pages/hoa'),
        },
        {
          path: '/utilities',
          load: () => import('./portal/pages/utilities'),
        },
        {
          path: '',
          load: () => import('./portal/pages/home'),
        },
      ],
    },
    {
      enableSSR: true,
      path: '/hub',
      children: [
        {
          path: '/my-budget',
          action(context: DivvyRouteContext) {
            return redirectAction(context, '/portal/budget');
          },
        },
        {
          path: '/documents',
          children: [
            {
              path: '',
              action(context: DivvyRouteContext) {
                return redirectAction(context, '/portal/documents');
              },
            },
          ],
        },
        {
          path: '/payments',
          children: [
            {
              path: '/success',
              action(context: DivvyRouteContext) {
                return redirectAction(context, '/portal/payments/success');
              },
            },
            {
              path: '/autopay/:action',
              action(context: DivvyRouteContext) {
                return redirectAction(context, `/portal/autopay/${context.params.action}`);
              },
            },
            {
              path: '/history',
              action(context: DivvyRouteContext) {
                return redirectAction(context, `/portal/payments/history`);
              },
            },
            {
              path: '',
              action(context: DivvyRouteContext) {
                return redirectAction(context, '/portal/payments');
              },
            },
          ],
        },
        {
          path: '/requests',
          children: [
            {
              path: '/new',
              action(context: DivvyRouteContext) {
                return redirectAction(context, '/portal/requests/new');
              },
            },
            {
              path: '/complete',
              action(context: DivvyRouteContext) {
                return redirectAction(context, '/portal/requests/complete');
              },
            },
            {
              path: '',
              action(context: DivvyRouteContext) {
                return redirectAction(context, '/portal/requests');
              },
            },
          ],
        },
        {
          path: '/dashboard',
          action(context: DivvyRouteContext) {
            return redirectAction(context, '/portal');
          },
        },
        {
          path: '/approval-requests',
          children: [
            {
              path: '/:approvalRequestId',
              action(context: DivvyRouteContext) {
                return redirectAction(
                  context,
                  `/portal/approval-requests/${context.params.approvalRequestId}`,
                );
              },
            },
          ],
        },
        {
          path: '',
          action(context: DivvyRouteContext) {
            return redirectAction(context, '/portal');
          },
        },
      ],
    },
    {
      path: '/login',
      children: [
        {
          path: ':loginRedirect',
          load: () => import('./accounts/pages/Login'),
        },
        {
          path: '',
          action() {
            return { redirect: `/accounts/login${window.location.search}` };
          },
        },
      ],
    },
    {
      path: '/register',
      children: [
        {
          path: ':registerRedirect',
          load: () => import('./accounts/pages/Register'),
        },
        {
          path: '',
          action() {
            return { redirect: `/accounts/register${window.location.search}` };
          },
        },
      ],
    },
    {
      path: '/missing-application',
      children: [
        {
          path: '',
          load: () => import('./process/pages/MissingApplication'),
        },
      ],
    },
    {
      enableSSR: true,
      path: '/process',
      children: [
        {
          path: '/summary',
          children: [
            {
              path: '',
              action(context: DivvyRouteContext) {
                return redirectAction(context, '/portal/application-summary');
              },
            },
          ],
        },
        {
          path: '/:stepId/decline-reasons',
          load: () => import('./process/pages/DeclineReasons'),
        },
        {
          path: '/:stepId/:uiType/:pageIndex',
          children: [
            {
              path: '',
              load: () => import('./process/pages/ApplicationSteps'),
            },
          ],
        },
        // the ./process module handles all redirection when the route is incomplete
        // e.g. /process or /process/9fb17040-3e20-4a93-aec4-5b98f9823677/self-reported-info
        {
          path: '/:stepId/:uiType',
          load: () => import('./process'),
        },
        {
          path: '',
          load: () => import('./process'),
        },
      ],
    },
    {
      path: '/investments/:id',
      load: () => import('./investments'),
    },
    {
      enableSSR: true,
      path: '/search',
      children: [
        {
          enableSSR: true,
          path: '/:metro',
          load: () => import('./search/pages/Listings'),
        },
      ],
    },
    {
      enableSSR: true,
      path: '/rent-to-own-homes/:metro',
      load: () => import('./search/pages/Listings'),
    },
    {
      path: '/saved-listings',
      action(context: DivvyRouteContext) {
        return redirectAction(context, '/portal/favorited-homes');
      },
    },
    {
      enableSSR: true,
      path: '/profile',
      load: () => import('./profile'),
    },
    {
      enableSSR: true,
      path: '/calculator-embed/:listingId',
      load: () => import('./calculator/calculator-embed'),
    },
    {
      enableSSR: true,
      path: '/brokers',
      load: () => import('./agents/Clients'),
    },
    {
      enableSSR: true,
      path: '/agents',
      action(context: DivvyRouteContext) {
        const { user } = context.store.getState();
        if (!user) {
          return { redirect: '/accounts/register/agent' };
        }

        if (!isAgentRole(user.role)) {
          return { redirect: '/login' };
        }

        return context.next();
      },
      children: [
        {
          path: '/welcome',
          children: [
            {
              path: '/1',
              load: () => import('./agents/onboarding/Welcome'),
            },
            {
              path: '/2',
              load: () => import('./agents/onboarding/ValueProposition'),
            },
            {
              path: '/notifications',
              load: () => import('./agents/onboarding/Notifications'),
            },
            {
              path: '/verify',
              load: () => import('./agents/onboarding/VerifyEmail'),
            },
            {
              path: '',
              load: () => import('./agents/onboarding/Welcome'),
            },
          ],
        },
        {
          path: '',
          action(context: DivvyRouteContext) {
            const { user } = context.store.getState();
            const loginState = getLoginState(user);

            switch (loginState) {
              case loginStates.LOGGED_IN_AND_CONFIRMED:
                return context.next();
              case loginStates.LOGGED_IN_AND_NOT_CONFIRMED:
                return { redirect: '/agents/welcome' };
              case loginStates.NOT_LOGGED_IN:
                return { redirect: '/accounts/register/agent' };
              default:
                throw new CoreError(`Unexpected login state: ${loginState}`);
            }
          },
          children: [
            {
              path: '/client/:applicationId',
              children: [
                {
                  path: '/:clientTab',
                  load: () => import('./agents/ClientDetails'),
                },
                {
                  path: '',
                  load: () => import('./agents/ClientDetails'),
                },
              ],
            },
            {
              path: '/client-feed',
              load: () => import('./agents/client-feed'),
            },
            {
              path: '/clients',
              load: () => import('./agents/Clients'),
            },
            {
              path: '/refer',
              load: () => import('./agents/Referrals'),
            },
            {
              path: '/listings',
              load: () => import('./agents/AgentDispositionListings'),
            },
            {
              path: '/calculator',
              children: [
                {
                  path: '/listing/:listingId/application/:applicationId',
                  load: () => import('./agents/calculator/Calculator'),
                },
                {
                  path: '/listing/:listingId',
                  load: () => import('./agents/calculator/Calculator'),
                },
                {
                  path: '',
                  load: () => import('./agents/calculator/Search'),
                },
              ],
            },
            {
              path: '/offers',
              action(context: DivvyRouteContext) {
                const { user } = context.store.getState();
                if (user?.role === roles.PAY_PER_LEAD_AGENT) {
                  return { redirect: '/agents' };
                }

                return context.next();
              },
              children: [
                {
                  path: '/details',
                  load: () => import('./agents/Offers/UpdatedDetails'),
                },
                {
                  path: '/submit',
                  load: () => import('./agents/Offers/Submit'),
                },
                {
                  path: '/submit/listing-details',
                  load: () => import('./agents/Offers/ListingDetails'),
                },
                {
                  path: '/submit/additional-information',
                  load: () => import('./agents/Offers/AdditionalInformation'),
                },
                {
                  path: '/submit/brokerage-details',
                  load: () => import('./agents/Offers/BrokerageDetails'),
                },
                {
                  path: '/submit/review-brokerage-details',
                  load: () => import('./agents/Offers/ReviewBrokerageDetails'),
                },
                {
                  path: '/submit/completed',
                  load: () => import('./agents/Offers/OfferInitiationComplete'),
                },
                {
                  path: '/search',
                  load: () => import('./agents/Offers/Search'),
                },
                {
                  path: '',
                  load: () => import('./agents/Offers/Search'),
                },
              ],
            },
            {
              path: '/profile',
              action: () => ({ redirect: '/profile' }),
            },
            {
              path: '/promos',
              load: () => import('./agents/Promotions'),
            },
          ],
        },
        {
          path: '(.*)',
          load: () => import('./agents'),
        },
      ],
    },
    {
      path: '/health',
      load: () => import('./health'),
    },
    {
      enableSSR: true,
      path: '/404',
      load: () => import('./marketing/NotFound'),
    },
    {
      enableSSR: true,
      path: '/application',
      action() {
        return { redirect: '/process' };
      },
    },
    {
      enableSSR: true,
      path: '/partners-signup',
      children: [
        {
          path: '/login-sent',
          load: () => import('./partners/pages/LoginSent/LoginSent'),
        },
        {
          path: '/error',
          load: () => import('./partners/pages/SignupError/SignupError'),
        },
        {
          path: '/success',
          load: () => import('./partners/pages/SignupSuccess/SignupSuccess'),
        },
        {
          path: '',
          load: () => import('./partners/pages/SignUp/SignUp'),
        },
      ],
    },
    {
      path: '/survey/:id',
      load: () => import('./surveys/Survey'),
    },
    {
      enableSSR: true,
      path: '/troubleshooting',
      children: [
        {
          path: '',
          action() {
            return { redirect: 'https://help.divvyhomes.com' };
          },
        },
        {
          path: '/cache-reset',
          load: () => import('./troubleshooting/pages/CacheReset'),
        },
      ],
    },
    // Redirect admin links
    {
      path: '/admin',
      action() {
        return { redirect: '/admin' };
      },
    },
    ...marketingRoutes.map((route) => ({
      path: route,
      action() {
        const pathname = window.location.pathname;
        const pathnameNoTrail = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;
        if (window.production) {
          // Removes trailing slash and redirects back
          // This is to fix, e.g., https://www.divvyhomes.com/search/ (note trailing slash)
          // going to divvy-homes instead of marketing
          return { redirect: `https://www.divvyhomes.com${pathnameNoTrail}` };
        }
        // Redirects to the marketing site port on dev
        return { redirect: `http://localhost:3001${pathnameNoTrail}` };
      },
    })),
  ],

  async action({ next }: DivvyRouteContext) {
    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    if (route.title) {
      if (!route.title.includes('| Divvy')) {
        route.title = `${route.title} | Divvy`;
      }
    } else {
      route.title = 'Divvy';
    }
    route.description = route.description || '';

    return route;
  },
};

// The error page is available by permanent url for development mode
if (!isProductionClientEnv()) {
  routes.children?.unshift({
    path: '/error',
    // @ts-expect-error I think this error page is unused. Confirm and delete if so.
    action: import('./error'),
  });
  routes.children?.unshift({
    path: '/',
    action() {
      return { redirect: '/accounts/login' };
    },
  });
}

routes.children?.push(
  // Wildcard routes, e.g. { path: '', ... } (must go last)
  {
    enableSSR: true,
    path: '(.*)',
    action() {
      return { redirect: '/404' };
    },
  },
);

export default routes;
