/**
 * DivvyHomes (https://www.divvyhomes.com)
 * Copyright © 2016-present Divvy. All rights reserved.
 */

import 'whatwg-fetch';

import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import type { Location as HistoryLocation } from 'history';
import MockDate from 'mockdate';
import queryString from 'query-string';

import clientConfig from '@app/client-config';

import { assignAbsoluteUrl, isMarketingRoute } from '@client/utils/routing';
import { isNonEmptyString } from '@divvy-homes/utils';
import { ClientAppRoot } from './components/ClientAppRoot';
import createFetch from './createFetch';
import { updateLink, updateMeta } from './DOMUtils';
import history from './history';
import { setRuntimeVariable } from './redux/actions/runtime';
import configureStore from './redux/store/configureStore';
import router from './router';
import type { DivvyContext } from './types';
import { clientEnv, isProductionClientEnv } from './utils/client';

if (isProductionClientEnv()) {
  datadogRum.init({
    ...clientConfig.datadog,
    env: clientEnv(),
    // Specify a version number to identify the deployed version of your application in Datadog
    version: __GIT_COMMITHASH__,
    sampleRate: 1, // Sample 1% of all sessions
    trackInteractions: true,
  });
  Sentry.init({
    ...clientConfig.sentry,
  });
}

/* eslint-disable global-require */

const { store, persistor } = configureStore(window.App.state, { fetch, history });
// Let's lazy-load some state

// Global (context) variables that can be easily accessed from any React component
// https://facebook.github.io/react/docs/context.html
const context: DivvyContext = {
  // Universal HTTP client
  fetch: createFetch(fetch, {
    cookie: undefined,
    rootValue: undefined,
    context: undefined,
  }),
  // Initialize a new Redux store
  // http://redux.js.org/docs/basics/UsageWithReact.html
  store,
  storeSubscription: null,
};

// Switch off the native scroll restoration behavior and handle it manually
// https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
const scrollPositionsHistory = {} as Record<
  string | number | symbol,
  { scrollX: number; scrollY: number }
>;
if (window.history && 'scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

if (isNonEmptyString(window.mockServerTime) && !window.production) {
  MockDate.set(window.mockServerTime);
}

let onRenderComplete: (route: any, location: HistoryLocation<unknown> | undefined) => void =
  function initialRenderComplete(initialRoute) {
    document.title = initialRoute.title;
    const elem = document.getElementById('css');
    if (elem) {
      elem.parentNode?.removeChild(elem);
    }
    updateMeta('description', initialRoute.description);
    updateLink('canonical', initialRoute.canonical);
    onRenderComplete = function renderComplete(route, location) {
      document.title = route.title;

      updateMeta('description', route.description);
      updateLink('canonical', route.canonical);
      // Update necessary tags in <head> at runtime here, ie:
      // updateMeta('keywords', route.keywords);
      // updateCustomMeta('og:url', route.canonicalUrl);
      // updateCustomMeta('og:image', route.imageUrl);
      // updateLink('canonical', route.canonicalUrl);
      // etc.

      let scrollX = 0;
      let scrollY = 0;
      const pos = location?.key ? scrollPositionsHistory[location.key] : undefined;
      if (pos) {
        scrollX = pos.scrollX;
        scrollY = pos.scrollY;
      } else {
        const targetHash = location?.hash.substr(1);
        if (targetHash) {
          const target = document.getElementById(targetHash);
          if (target) {
            scrollY = window.pageYOffset + target.getBoundingClientRect().top;
          }
        }
      }

      // Restore the scroll position if it was saved into the state
      // or scroll to the given #hash anchor
      // or scroll to top of the page
      window.scrollTo(scrollX, scrollY);

      // Google Analytics tracking. Don't send 'pageview' event after
      // the initial rendering, as it was already sent
      // Google tag manager will now handle page load logging
    };
  };

let currentLocation = history?.location;

// Re-render the app when window.location changes
async function onLocationChange(location?: HistoryLocation, action?: string) {
  // Remember the latest scroll position for the previous location
  // but only if we have a location key...
  if (currentLocation?.key) {
    scrollPositionsHistory[currentLocation.key] = {
      scrollX: window.pageXOffset,
      scrollY: window.pageYOffset,
    };
  }
  // Delete stored scroll position for next page if any
  if (action === 'PUSH') {
    if (location?.key) {
      delete scrollPositionsHistory[location.key];
    }
    context.store.dispatch(setRuntimeVariable({ name: 'isNavigating', value: true }));
  }
  currentLocation = location;

  try {
    // Traverses the list of routes in the order they are defined until
    // it finds the first route that matches provided URL path string
    // and whose action method returns anything other than `undefined`.
    const route = await router.resolve({
      ...context,
      pathname: location?.pathname ?? '',
      query: queryString.parse(location?.search ?? ''),
    });
    setTimeout(() => {
      context.store.dispatch(setRuntimeVariable({ name: 'isNavigating', value: false }));
    }, 500);

    // Prevent multiple page renders during the routing process
    if (currentLocation?.key !== location?.key) {
      return;
    }

    if (route.redirect) {
      if (isMarketingRoute(route.redirect)) {
        assignAbsoluteUrl(route.redirect);
        return;
      }

      if (
        route.redirect.indexOf('/admin') >= 0 ||
        route.redirect.startsWith('http://') ||
        route.redirect.startsWith('https://')
      ) {
        window.location.assign(route.redirect);
        return;
      }
      history?.replace(route.redirect);
      return;
    }

    const onBeforeLift = async () => {
      // TODO: Nothing is actually being saved here anymore, but I'd need to spend more time
      // validating that the rest of this code can be deleted

      persistor.persist();
    };

    let bootstrapped = false;
    persistor.subscribe(() => {
      if (!bootstrapped) {
        bootstrapped = true;
        onBeforeLift();
      }
    });

    // Update the HotJar location
    // See https://help.hotjar.com/hc/en-us/articles/360034378534
    if (window.hj && window.hj.locationListener) {
      window.hj.locationListener.setMode('manual');
      window.hj('stateChange', route.path);
    }

    // Update Hubspot location
    // Docs: https://legacydocs.hubspot.com/docs/methods/conversations_api/hubspot-conversations-javascript-api
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.refresh();
    } else {
      window.hsConversationsOnReady = [
        () => {
          window.HubSpotConversations?.widget.refresh();
        },
      ];
    }

    await ClientAppRoot(context, route, () => onRenderComplete(route, location));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (!isProductionClientEnv()) {
      if (!__IS_DEVELOPMENT_BUILD__) {
        // this is staging
        alert(error); // eslint-disable-line no-alert
      }
      throw error;
    }

    console.error(error);

    // Do a full page reload if error occurs during client-side navigation
    if (action && currentLocation?.key === location?.key) {
      window.location.reload();
    }
  }
}

// Handle client-side navigation by using HTML5 History API
// For more information visit https://github.com/mjackson/history#readme
history?.listen(onLocationChange);
onLocationChange(currentLocation);
