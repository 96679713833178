import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import cookie from 'cookies-js';
import { find, isNil } from 'lodash';
import ReactDOM from 'react-dom';
import { IMPERSONATION_COOKIE_KEY } from '@app/authorization/constants';

import type { BuybackPrice } from '@app/ledger/leases/types';
import { AmplitudeProvider } from '@client/components/Amplitude/components/AmplitudeProvider';
import {
  identifyForAdminProject,
  identifyForConsumerProject,
  setAdminUserProperties,
  setUserProperties,
} from '@client/metrics/analytics';
import { reduxCacheInitialize } from '@client/redux/store/cache/redux-cache';
import type { DivvyContext, DivvyRouteContext } from '@client/types';
import { INTERNAL_USER_ROLES } from 'src/constants/user';
import { AnalyticsUserHydration } from './AnalyticsUserHydration';
import { App } from './App';

const graphqlLink = createHttpLink({
  uri: '/graphql',
});

const graphqlExtraHeaders = setContext(async (_, { headers }) => ({
  headers: {
    ...headers,
    callerPathname: window.location.pathname,
    callerSearch: window.location.search,
  },
}));

const apolloClient = new ApolloClient({
  connectToDevTools: __IS_DEVELOPMENT_BUILD__,
  link: graphqlExtraHeaders.concat(graphqlLink),
  cache: new InMemoryCache({
    typePolicies: {
      AgentPromotion: {
        keyFields: ['code', 'agentProfileId'],
      },
      DivvyAccountEquityBalances: {
        fields: {
          buybackPrices: {
            merge: (existing, incoming) => {
              if (!existing) {
                return incoming;
              }
              return incoming.map((price: BuybackPrice) => {
                const { startDate, endDate, month } = price;
                if (isNil(month) && (isNil(startDate) || isNil(endDate))) {
                  return price;
                }
                const match = month ? { month } : { startDate, endDate };
                const existingPrice = find(existing, match);
                return {
                  ...existingPrice,
                  ...price,
                };
              });
            },
          },
        },
      },
    },
  }).restore((window as any).__APOLLO_STATE__),
});

export const ClientAppRoot = async (
  context: DivvyContext,
  route: DivvyRouteContext,
  cb: VoidFunction,
) => {
  const state = context.store.getState();
  reduxCacheInitialize(context);

  // TODO remove all of this once we switch fully to AmplitudeContext
  const isImpersonating = cookie.get(IMPERSONATION_COOKIE_KEY);
  if (state.user && state.user.id) {
    if (!isImpersonating) {
      identifyForConsumerProject(state.user.id);
      setUserProperties({
        role: state.user.role,
        applicationId: state.user.applicationId ?? undefined,
      });
      if (INTERNAL_USER_ROLES.includes(state.user.role as (typeof INTERNAL_USER_ROLES)[number])) {
        identifyForAdminProject(state.user.id);
        setAdminUserProperties({
          role: state.user.role,
        });
      }
    } else {
      setUserProperties({
        lastImpersonatedUserId: state.user.id,
      });
      setAdminUserProperties({
        lastImpersonatedUserId: state.user.id,
      });
    }
  }
  if (state.user && state.user.id && !isImpersonating) {
    identifyForConsumerProject(state.user.id);
    setUserProperties({
      role: state.user.role,
      applicationId: state.user.applicationId ?? undefined,
    });
    if (INTERNAL_USER_ROLES.includes(state.user.role as (typeof INTERNAL_USER_ROLES)[number])) {
      identifyForAdminProject(state.user.id);
      setAdminUserProperties({
        role: state.user.role,
      });
    }
  }

  // TODO - ReactDOM.hydrate should be more performant but we have some bugs
  const renderMethod = ReactDOM.render;

  renderMethod(
    <AmplitudeProvider
      enable={
        (!__IS_DEVELOPMENT_BUILD__ || (global.window as any).amplitudeDev) && !global.window.Cypress
      }
    >
      {state.user && <AnalyticsUserHydration user={state.user!} />}
      <ApolloProvider client={apolloClient}>
        <App context={context}>{route.component}</App>
      </ApolloProvider>
    </AmplitudeProvider>,
    document.getElementById('app'),
    cb,
  );
};
