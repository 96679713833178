import { stages } from '@app/application/constants';
import { DivvyReduxUser } from '@app/client/types';
import { roles } from '@app/constants/user';

const clientStatuses = {
  SHOWINGS_SCHEDULED: 'SHOWINGS_SCHEDULED',
  IN_COMMUNICATION: 'IN_COMMUNICATION',
  BUDGET_QUESTIONS: 'BUDGET_QUESTIONS',
  NOT_MOVING_SOON: 'NOT_MOVING_SOON',
  UNRESPONSIVE: 'UNRESPONSIVE',
  OUTSIDE_AREA: 'OUTSIDE_AREA',
  OTHER: 'OTHER',
};

const clientStatusMap = {
  [clientStatuses.SHOWINGS_SCHEDULED]: {
    label: "We're attending showings and are on track for an offer!",
    value: clientStatuses.SHOWINGS_SCHEDULED,
    display: 'Showings Scheduled',
    hide: false,
  },
  [clientStatuses.IN_COMMUNICATION]: {
    label: "We're in contact but haven't yet gone on showings.",
    value: clientStatuses.IN_COMMUNICATION,
    display: "We're in Communication",
    hide: false,
  },
  [clientStatuses.BUDGET_QUESTIONS]: {
    label: 'This client has questions about budget and application. Please assist!',
    value: clientStatuses.BUDGET_QUESTIONS,
    display: 'Has questions',
    hide: false,
  },
  [clientStatuses.NOT_MOVING_SOON]: {
    label: 'This client is not moving within 3 months',
    value: clientStatuses.NOT_MOVING_SOON,
    display: 'Not moving soon',
    hide: true,
  },
  [clientStatuses.UNRESPONSIVE]: {
    label: 'This client is unresponsive or not interested in Divvy',
    value: clientStatuses.UNRESPONSIVE,
    display: 'Unresponsive',
    hide: false,
  },
  [clientStatuses.OUTSIDE_AREA]: {
    label: 'This client is shopping outside my area',
    value: clientStatuses.OUTSIDE_AREA,
    display: 'Outside my area',
    hide: true,
  },
  [clientStatuses.OTHER]: {
    label: 'Other',
    value: clientStatuses.OTHER,
    display: 'Other',
    hide: false,
  },
} as const;

const updateRequiredStages = [
  stages.PRE_APPROVED,
  stages.CONDITIONALLY_APPROVED,
  stages.UNDERWRITING_HOLD,
];

const loginStates = {
  NOT_LOGGED_IN: 'NOT_LOGGED_IN',
  LOGGED_IN_AND_CONFIRMED: 'LOGGED_IN_AND_CONFIRMED',
  LOGGED_IN_AND_NOT_CONFIRMED: 'LOGGED_IN_AND_NOT_CONFIRMED',
};

const getLoginState = (user: DivvyReduxUser | null | undefined) => {
  if (user && user.role === roles.AGENT) {
    if (!user.confirmed) {
      return loginStates.LOGGED_IN_AND_NOT_CONFIRMED;
    }
    return loginStates.LOGGED_IN_AND_CONFIRMED;
  }
  // No need to confirm PPL agents
  if (user && user.role === roles.PAY_PER_LEAD_AGENT) {
    return loginStates.LOGGED_IN_AND_CONFIRMED;
  }
  return loginStates.NOT_LOGGED_IN;
};

export type ClientStatus = (typeof clientStatusMap)[keyof typeof clientStatusMap];

export { clientStatusMap, clientStatuses, updateRequiredStages, loginStates, getLoginState };
