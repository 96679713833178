/**
 * DivvyHomes (https://www.divvyhomes.com)
 * Copyright © 2016-present Divvy. All rights reserved.
 */

import Router, { Route } from 'universal-router';

import routes from './routes';
import { DivvyRouteContext, DivvyRoute } from './types';

export default new Router<any, DivvyRouteContext>(routes as Route<any, DivvyRouteContext>, {
  resolveRoute(contextIn, params) {
    // Casts unfortunately seem to be necessary here
    const context = contextIn as unknown as DivvyRouteContext;
    const route = context.route as DivvyRoute;

    // If we are attempting to render on the server, skip that UNLESS we
    // explicitly want to render on the server
    if (context.isServerSide && !route.enableSSR) {
      return { component: null };
    }
    if (typeof route.load === 'function') {
      return route.load().then((action) => action.default(context, params));
    }
    if (typeof route.action === 'function') {
      return route.action(context, params);
    }
    return undefined;
  },
});
