import { stages } from '@app/application/constants';
import { isTenancyTypeActive } from '@app/constants/models';
import type { DivvyContext } from '@client/types';

export const getAccessiblePortalPathnameRegexes = (context: DivvyContext) => {
  const state = context.store.getState();
  const user = state.user;
  if (!user) {
    return [];
  }

  const accessibleLinks = [];
  if (
    [
      stages.CONDITIONALLY_APPROVED,
      stages.UNDERWRITING_HOLD,
      stages.PRE_APPROVED,
      stages.CLOSED,
    ].includes(user.dealStage) ||
    // We shouldn't need this because any user with tenancy should be in the CLOSED stage
    // but of course that's not the case
    user.tenancyType
  ) {
    accessibleLinks.push('/portal');
    accessibleLinks.push('/portal/dashboard');
    accessibleLinks.push('/portal/tasks');
    accessibleLinks.push('/portal/approval-requests/.*');
    if (user.tenancyType) {
      accessibleLinks.push('/portal/documents');
      accessibleLinks.push('/portal/payments');
      accessibleLinks.push('/portal/faq');
      accessibleLinks.push('/portal/faq/flexible-payments');
      accessibleLinks.push('/portal/payments/history');
      accessibleLinks.push('/portal/payments/success');
      accessibleLinks.push('/portal/payments/autopay/.*');
      if (user.hasHOA) {
        accessibleLinks.push('/portal/hoa');
      }
      if (user.hasUtility) {
        accessibleLinks.push('/portal/utilities');
      }
      if (isTenancyTypeActive(user.tenancyType)) {
        accessibleLinks.push('/portal/requests');
        accessibleLinks.push('/portal/requests/new');
        accessibleLinks.push('/portal/requests/complete');
      }
    } else {
      accessibleLinks.push('/search');
      accessibleLinks.push('/portal/budget');
      accessibleLinks.push('/portal/my-budget');
    }
  }
  if (!user.tenancyType) {
    accessibleLinks.push('/portal/application-summary');
    accessibleLinks.push('/portal/favorited-homes');
  }
  if (user.chipProfileId) {
    accessibleLinks.push('/up');
  }
  accessibleLinks.push('/portal/profile');

  return accessibleLinks;
};

export const matchesAny = (pathnameRegexes: string[], pathname: string) =>
  pathnameRegexes.filter((link) => new RegExp(`^${link}$`).test(pathname)).length > 0;
